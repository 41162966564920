import { h } from 'preact'; // if folder:
//   + checkbox (selects all files from folder)
//   + folder name (opens folder)
// if file:
//   + checkbox (selects file)
//   + file name (selects file)

function ListItem(props) {
  var className = props.className,
      isDisabled = props.isDisabled,
      restrictionError = props.restrictionError,
      isCheckboxDisabled = props.isCheckboxDisabled,
      isChecked = props.isChecked,
      toggleCheckbox = props.toggleCheckbox,
      recordShiftKeyPress = props.recordShiftKeyPress,
      type = props.type,
      id = props.id,
      itemIconEl = props.itemIconEl,
      title = props.title,
      handleFolderClick = props.handleFolderClick,
      showTitles = props.showTitles,
      i18n = props.i18n;
  return h("li", {
    className: className,
    title: isDisabled ? restrictionError == null ? void 0 : restrictionError.message : null
  }, !isCheckboxDisabled ? h("input", {
    type: "checkbox",
    className: "uppy-u-reset uppy-ProviderBrowserItem-checkbox ".concat(isChecked ? 'uppy-ProviderBrowserItem-checkbox--is-checked' : ''),
    onChange: toggleCheckbox,
    onKeyDown: recordShiftKeyPress // for the <label/>
    ,
    name: "listitem",
    id: id,
    checked: isChecked,
    "aria-label": type === 'file' ? null : i18n('allFilesFromFolderNamed', {
      name: title
    }),
    disabled: isDisabled,
    "data-uppy-super-focusable": true
  }) : null, type === 'file' ? // label for a checkbox
  h("label", {
    htmlFor: id,
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner"
  }, h("div", {
    className: "uppy-ProviderBrowserItem-iconWrap"
  }, itemIconEl), showTitles && title) : // button to open a folder
  h("button", {
    type: "button",
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner",
    onClick: handleFolderClick,
    "aria-label": i18n('openFolderNamed', {
      name: title
    })
  }, h("div", {
    className: "uppy-ProviderBrowserItem-iconWrap"
  }, itemIconEl), showTitles && h("span", null, title)));
}

export default ListItem;