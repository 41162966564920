/**
 * Little AbortController proxy module so we can swap out the implementation easily later.
 */
var AbortController = globalThis.AbortController;
export { AbortController };
var AbortSignal = globalThis.AbortSignal;
export { AbortSignal };
export var createAbortError = function createAbortError(message) {
  if (message === void 0) {
    message = 'Aborted';
  }

  return new DOMException(message, 'AbortError');
};