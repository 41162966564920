import { h } from 'preact';
export default (function (_ref) {
  var currentDeviceId = _ref.currentDeviceId,
      audioSources = _ref.audioSources,
      onChangeSource = _ref.onChangeSource;
  return h("div", {
    className: "uppy-Audio-videoSource"
  }, h("select", {
    className: "uppy-u-reset uppy-Audio-audioSource-select",
    onChange: function onChange(event) {
      onChangeSource(event.target.value);
    }
  }, audioSources.map(function (audioSource) {
    return h("option", {
      key: audioSource.deviceId,
      value: audioSource.deviceId,
      selected: audioSource.deviceId === currentDeviceId
    }, audioSource.label);
  })));
});