import { h } from 'preact';

function GridListItem(props) {
  var className = props.className,
      isDisabled = props.isDisabled,
      restrictionError = props.restrictionError,
      isChecked = props.isChecked,
      title = props.title,
      itemIconEl = props.itemIconEl,
      showTitles = props.showTitles,
      toggleCheckbox = props.toggleCheckbox,
      recordShiftKeyPress = props.recordShiftKeyPress,
      id = props.id,
      children = props.children;
  return h("li", {
    className: className,
    title: isDisabled ? restrictionError == null ? void 0 : restrictionError.message : null
  }, h("input", {
    type: "checkbox",
    className: "uppy-u-reset uppy-ProviderBrowserItem-checkbox ".concat(isChecked ? 'uppy-ProviderBrowserItem-checkbox--is-checked' : '', " uppy-ProviderBrowserItem-checkbox--grid"),
    onChange: toggleCheckbox,
    onKeyDown: recordShiftKeyPress,
    name: "listitem",
    id: id,
    checked: isChecked,
    disabled: isDisabled,
    "data-uppy-super-focusable": true
  }), h("label", {
    htmlFor: id,
    "aria-label": title,
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner"
  }, h("span", {
    className: "uppy-ProviderBrowserItem-inner-relative"
  }, itemIconEl, showTitles && title, children)));
}

export default GridListItem;