import { h } from 'preact';
import classNames from 'classnames';
import statusBarStates from './StatusBarStates.js';
import calculateProcessingProgress from './calculateProcessingProgress.js';
import { UploadBtn, RetryBtn, CancelBtn, PauseResumeButton, DoneBtn, ProgressBarProcessing, ProgressBarError, ProgressBarUploading, ProgressBarComplete } from "./Components.js";
var STATE_ERROR = statusBarStates.STATE_ERROR,
    STATE_WAITING = statusBarStates.STATE_WAITING,
    STATE_PREPROCESSING = statusBarStates.STATE_PREPROCESSING,
    STATE_UPLOADING = statusBarStates.STATE_UPLOADING,
    STATE_POSTPROCESSING = statusBarStates.STATE_POSTPROCESSING,
    STATE_COMPLETE = statusBarStates.STATE_COMPLETE; // TODO: rename the function to StatusBarUI on the next major.

export default function StatusBar(props) {
  var newFiles = props.newFiles,
      allowNewUpload = props.allowNewUpload,
      isUploadInProgress = props.isUploadInProgress,
      isAllPaused = props.isAllPaused,
      resumableUploads = props.resumableUploads,
      error = props.error,
      hideUploadButton = props.hideUploadButton,
      hidePauseResumeButton = props.hidePauseResumeButton,
      hideCancelButton = props.hideCancelButton,
      hideRetryButton = props.hideRetryButton,
      recoveredState = props.recoveredState,
      uploadState = props.uploadState,
      totalProgress = props.totalProgress,
      files = props.files,
      supportsUploadProgress = props.supportsUploadProgress,
      hideAfterFinish = props.hideAfterFinish,
      isSomeGhost = props.isSomeGhost,
      doneButtonHandler = props.doneButtonHandler,
      isUploadStarted = props.isUploadStarted,
      i18n = props.i18n,
      startUpload = props.startUpload,
      uppy = props.uppy,
      isAllComplete = props.isAllComplete,
      showProgressDetails = props.showProgressDetails,
      numUploads = props.numUploads,
      complete = props.complete,
      totalSize = props.totalSize,
      totalETA = props.totalETA,
      totalUploadedSize = props.totalUploadedSize;

  function getProgressValue() {
    switch (uploadState) {
      case STATE_POSTPROCESSING:
      case STATE_PREPROCESSING:
        {
          var progress = calculateProcessingProgress(files);

          if (progress.mode === 'determinate') {
            return progress.value * 100;
          }

          return totalProgress;
        }

      case STATE_ERROR:
        {
          return null;
        }

      case STATE_UPLOADING:
        {
          if (!supportsUploadProgress) {
            return null;
          }

          return totalProgress;
        }

      default:
        return totalProgress;
    }
  }

  function getIsIndeterminate() {
    switch (uploadState) {
      case STATE_POSTPROCESSING:
      case STATE_PREPROCESSING:
        {
          var _calculateProcessingP = calculateProcessingProgress(files),
              mode = _calculateProcessingP.mode;

          return mode === 'indeterminate';
        }

      case STATE_UPLOADING:
        {
          if (!supportsUploadProgress) {
            return true;
          }

          return false;
        }

      default:
        return false;
    }
  }

  function getIsHidden() {
    if (recoveredState) {
      return false;
    }

    switch (uploadState) {
      case STATE_WAITING:
        return hideUploadButton || newFiles === 0;

      case STATE_COMPLETE:
        return hideAfterFinish;

      default:
        return false;
    }
  }

  var progressValue = getProgressValue();
  var isHidden = getIsHidden();
  var width = progressValue != null ? progressValue : 100;
  var showUploadBtn = !error && newFiles && !isUploadInProgress && !isAllPaused && allowNewUpload && !hideUploadButton;
  var showCancelBtn = !hideCancelButton && uploadState !== STATE_WAITING && uploadState !== STATE_COMPLETE;
  var showPauseResumeBtn = resumableUploads && !hidePauseResumeButton && uploadState === STATE_UPLOADING;
  var showRetryBtn = error && !isAllComplete && !hideRetryButton;
  var showDoneBtn = doneButtonHandler && uploadState === STATE_COMPLETE;
  var progressClassNames = classNames('uppy-StatusBar-progress', {
    'is-indeterminate': getIsIndeterminate()
  });
  var statusBarClassNames = classNames('uppy-StatusBar', "is-".concat(uploadState), {
    'has-ghosts': isSomeGhost
  });
  return h("div", {
    className: statusBarClassNames,
    "aria-hidden": isHidden
  }, h("div", {
    className: progressClassNames,
    style: {
      width: "".concat(width, "%")
    },
    role: "progressbar",
    "aria-label": "".concat(width, "%"),
    "aria-valuetext": "".concat(width, "%"),
    "aria-valuemin": "0",
    "aria-valuemax": "100",
    "aria-valuenow": progressValue
  }), function () {
    switch (uploadState) {
      case STATE_PREPROCESSING:
      case STATE_POSTPROCESSING:
        return h(ProgressBarProcessing, {
          progress: calculateProcessingProgress(files)
        });

      case STATE_COMPLETE:
        return h(ProgressBarComplete, {
          i18n: i18n
        });

      case STATE_ERROR:
        return h(ProgressBarError, {
          error: error,
          i18n: i18n,
          numUploads: numUploads,
          complete: complete
        });

      case STATE_UPLOADING:
        return h(ProgressBarUploading, {
          i18n: i18n,
          supportsUploadProgress: supportsUploadProgress,
          totalProgress: totalProgress,
          showProgressDetails: showProgressDetails,
          isUploadStarted: isUploadStarted,
          isAllComplete: isAllComplete,
          isAllPaused: isAllPaused,
          newFiles: newFiles,
          numUploads: numUploads,
          complete: complete,
          totalUploadedSize: totalUploadedSize,
          totalSize: totalSize,
          totalETA: totalETA,
          startUpload: startUpload
        });

      default:
        return null;
    }
  }(), h("div", {
    className: "uppy-StatusBar-actions"
  }, recoveredState || showUploadBtn ? h(UploadBtn, {
    newFiles: newFiles,
    isUploadStarted: isUploadStarted,
    recoveredState: recoveredState,
    i18n: i18n,
    isSomeGhost: isSomeGhost,
    startUpload: startUpload,
    uploadState: uploadState
  }) : null, showRetryBtn ? h(RetryBtn, {
    i18n: i18n,
    uppy: uppy
  }) : null, showPauseResumeBtn ? h(PauseResumeButton, {
    isAllPaused: isAllPaused,
    i18n: i18n,
    isAllComplete: isAllComplete,
    resumableUploads: resumableUploads,
    uppy: uppy
  }) : null, showCancelBtn ? h(CancelBtn, {
    i18n: i18n,
    uppy: uppy
  }) : null, showDoneBtn ? h(DoneBtn, {
    i18n: i18n,
    doneButtonHandler: doneButtonHandler
  }) : null));
}